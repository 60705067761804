/*
 * @Author: chaixiaobu 13817847080@163.com
 * @Date: 2024-08-14 10:34:03
 * @LastEditors: chaijunlin chaijunlin@sanfawork.com
 * @LastEditTime: 2024-11-07 17:11:36
 * @FilePath: \chai-activity-h5\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'Index',
    meta: {
      title: '福利官',
    },
    component: () => import('@/pages/welfare/views/index.vue'),
  },
  {
    path: '/search',
    component: () => import('@/pages/welfare/views/search.vue'),
    meta: {
      title: '搜索',
    },
  },
  // 替代vue2中的'*'通配符路径
  { path: '/:pathMatch(.*)*', redirect: '/' },
]
const router = createRouter({
  history: createWebHistory(process.env.VITE_BASE_URL), // history 模式则使用 createWebHistory()
  routes,
})
export default router
