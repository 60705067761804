/*
 * @Author: chaixiaobu 13817847080@163.com
 * @Date: 2024-08-14 10:34:03
 * @LastEditors: chaixiaobu 13817847080@163.com
 * @LastEditTime: 2024-08-27 15:13:40
 * @FilePath: \chai-activity-h5\src\utils\http\axios.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import type { Response } from './types'
// import { auth } from '@/utils';
import { showFailToast } from 'vant'
import router from '@/router'

axios.defaults.timeout = 1000 * 60
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// 创建axios实例
const service = axios.create({
  // 根据不同env设置不同的baseURL
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
})

// axios实例拦截请求
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = {
      ...config.headers,
      // ...auth.headers(), // 你的自定义headers，如token等
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

// axios实例拦截响应
service.interceptors.response.use(
  // 2xx时触发
  (response: AxiosResponse<Response>) => {
    // response.data就是后端返回的数据，结构根据你们的约定来定义
    const { code, message } = response.data
    let errMessage = ''
    switch (code) {
      case 0:
        break
      case 1: // token过期
        errMessage = 'Token expired'
        router.push('/login')
        break
      case 2: // 无权限
        errMessage = 'No permission'
        break
      default:
        errMessage = message
        break
    }
    if (errMessage) showFailToast(errMessage)
    return response
  },
  // 非2xx时触发
  (error: AxiosError) => {
    showFailToast('Network Error...')
    return Promise.reject(error)
  }
)

export type { AxiosResponse, AxiosRequestConfig }

export default service
